import React, { useState } from "react";
import "../../style.css";
import { Container, Row } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SubscribeForm = () => {
  const [status, setStatus] = useState(null)
  const [email, setEmail] = useState("")
  const [vorname, setVorname] = useState("")
  const navigate = useNavigate();

  const handleSubmit = async event => {
    event.preventDefault()

      try{
        const payload = {
          "email": email,
          "vorname": vorname
          }
        
      const response =  await axios.post("/api/sub", payload)

      setEmail("")
      setVorname("")
      const json = response
      if (json.status === 200) {
        setStatus("SUCCESS")
        navigate("/SuccessfulSignup", {state: {vorname, email}});
      }
    } catch (err) {
        if (setStatus === "SUCCESS"){
          return
        }
      setStatus("ERROR")
      console.log(err)
    }
  }

  const handleEmailChange = event => {
    const { value } = event.target
    setEmail(value)
  }

  const handleNameChange = event => {
    const { value } = event.target
    setVorname(value)
  }

  return (
    <Container id="newsletter">
        <Container>
          <Row>
        <div className="Newsletter" id="Newsletter">
          <section>
            <h2 id="News" className="home-about-mission">Newsletter</h2>
            <p>Ich lade dich von Herzen ein, Teil meiner Community zu werden. Ich teile regelmäßig Neuigkeiten zu meinem Angebot, hilfreiche Tipps für deine Yoga Praxis sowie Inspirationen und Gedanken zu Themen rund um Achtsamkeit und Selbstfürsorge.</p>
            {status === "SUCCESS" && (
              <>
                <h1>
                  Willkommen!{vorname ? `, ${vorname}` : ""}{" "}
                </h1>
                <p>Du wirst in wenigen Minuten eine Email bekommen in der du deine Email Adresse bestätigen musst</p>
              </>
            )}
            {status === "ERROR" && (
              <>
                <h1>Oops, ein Problem ist aufgetreten..</h1>
                <p>
                  Überprüfe deine Eingaben und probiere es erneut
                </p>
              </>
            )}
            <div>
            <form onSubmit={handleSubmit}>
              <input 
                type="text"
                placeholder="Vorname"
                value={vorname}
                onChange={handleNameChange}
                required
              />
              <br></br>
              <input 
                type="text"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <br/>
              <button fluid="true" className="button"
              >
                Abschicken
              </button>
              </form>
            </div>
          </section>
        </div>
        </Row>
        </Container>
      </Container>
  )
}

export default SubscribeForm