import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";

function Impressum() {
  return (
    <section>
    <Particle />
      <Container fluid="true" className="ds-about-description">
        <Container className="imp-about-section">
            <h1>Impressum</h1>

<p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
Offenlegungspflicht laut §25 Mediengesetz.</p>
<p>The Mermaid Yoga e.U.<br/>
Bianca Bleicher, BSc MA<br/>
Wilhelm Lebsaft-Gasse 2a/2<br/>
3400 Klosterneuburg<br/>
Österreich<br/>
<br></br>
Unternehmensgegenstand: Erstellung von Trainingskonzepten für gesundheitsbewusste Personen<br/>
Firmenbuchnummer: 609032k<br/>
Firmenbuchgericht: Landesgericht Korneuburg<br/>
Mitgliedschaft WKO: Mitglied der WKÖ, WKNÖ, Fachgruppe Freizeit- und Sportbetriebe<br/>
Gewerbeordnung: <a href="www.ris.bka.gv.at">www.ris.bka.gv.at</a><br/>
Aufsichtsbehörde / Gewerbebehörde: Bezirkshauptmannschaft Tulln<br/>
Richtung der Website („Blattlinie“): Informationen und Kursangebote zu den Themen
Yoga und Achtsamkeit.</p>

<p><b>Kontakt:</b><br/>

E-Mail: office@themermaid.yoga<br/>
Website: www.themermaid.yoga<br/>
Telefon: +43 677 6120998200</p>
<h2>Kontaktdaten des Verantwortlichen für Datenschutz</h2>
<p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen
Person bzw. Stelle:</p>
<p>The Mermaid Yoga e.U.<br/>
Wilhelm Lebsaft Gasse 2a/2, 3400 Klosterneuburg, Österreich<br/>
Vertretungsberechtigt: Bianca Bleicher, BSc MA<br/>
E-Mail-Adresse: office@themermaid.yoga<br/> </p>
<h2>EU-Streitschlichtung</h2>
<p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten
wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a><br/>
Zur Teilnahme an Streitschlichtungsverfahren vor einer Verbraucherschlichtungss telle sind wir weder
bereit noch verpflichtet.</p>
<p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
<h2>Haftung für Inhalte dieser Website</h2>
<p>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle Informationen
bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen,
speziell für jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von
ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf
eine rechtswidrige Tätigkeit hinweisen.</p>
<p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen
nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle
unserer Nichtverantwortlichkeit davon unberührt.</p>
<p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren,
damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
<h2>Haftung für Links auf dieser Website</h2>
<p>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind. Haftung für
verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns
solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns
Rechtswidrigkeiten bekannt werden.</p>

<p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die
Kontaktdaten im Impressum.</p>
<h2>Urheberrechtshinweis</h2>
<p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns
bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen
Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer
Seite rechtlich verfolgen.</p>
<p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
kontaktieren.</p>
<p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
<li>The Mermaid Yoga</li>
<li>Unsplash</li>
<li><a href="https://www.flaticon.com/free-icons/unicorn" title="unicorn icons">Unicorn icons created by Freepik - Flaticon</a></li>
        </Container>
      </Container>
    </section>
  );
}

export default Impressum;
