import React from "react";
import { Container, Row, Col, Card, Button, CardGroup } from "react-bootstrap";
import Particle from "../Particle";
import yoga from "../../Assets/yoga2.webp";
import mindfulness from "../../Assets/mindfulness2.webp"
import { useNavigate } from 'react-router-dom';

function BlogMain() {
  const navigate = useNavigate();

  const routeChangeY = () =>{ 
    navigate("/BlogYoga");
  }

  const routeChangeM = () =>{ 
    navigate("/BlogMindfulness");
  }

  return (
    <Container fluid="true" className="blog-section" id="Blog">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              The Mermaid Yoga Blog
            </h1>
        </Row>
        <CardGroup>
        <Row>
        <Col md={6}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
          <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
            <Card.Img variant="top" src={yoga} />
            <Card.Body>
              <Card.Title>Yoga</Card.Title>
              <Card.Text>
              Hier findest du Beiträge über die Welt des Yoga in all ihren Facetten. Tauche ein in diese faszinierende Praxis, finde Inspirationen für deine eigene Yoga-Reise und erfahre, wie Yoga Körper und Geist in Einklang bringt.
              </Card.Text>
            </Card.Body>
            <Button className="blog-button" onClick={routeChangeY}><b>Zu den Beiträgen</b></Button>
          </Card>
          </Col>

          <Col md={6}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
          <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
            <Card.Img variant="top" src={mindfulness} />
            <Card.Body>
              <Card.Title>Mindfulness</Card.Title>
              <Card.Text>
              Hier findest du einen Raum, um dich selbst zu entdecken und deine innere Ruhe zu finden. Ich teile praktische Tipps, Erfahrungen, inspirierende Geschichten und bewährte Methoden, um deine mentale und emotionale Gesundheit zu stärken. Tauche ein in die Welt der Achtsamkeit und lerne, wie du für dich selbst sorgen kannst, um ein erfüllteres Leben zu führen.
              </Card.Text>
            </Card.Body>
            <Button className="blog-button" onClick={routeChangeM}><b>Zu den Beiträgen</b></Button>
          </Card>
        
</Col>

        </Row>
        </CardGroup>
      </Container>
    </Container>
  );
}

export default BlogMain;
